import {get,qspost,deletefn} from '@/plugins/axios'


/*
 * 用户自主建站的申请列表
 * @constructor jack
 * */
export const getUserApplyWebList = (page,limit) => get('/Home/Order/getUserApplyWebList', {page,limit})

/*
 * 用户自主建站的申请列表
 * @constructor jack
 * */
export const getWebModelList = (page,limit) => get('/Home/Order/webList', {page,limit})

/*
 * 用户取消（删除）申请
 * @constructor jack
 * */
export const userDeleteApplyWeb = (id) => deletefn('/Home/Order/userDeleteApplyWeb', {id})

/*
 * 获取管理端token
 * @constructor jack
 * */
export const getManageToken = (order_id) => qspost('/home/Manage/getToken', {order_id})

/*
 * 用户申请的详情
 * @constructor jack
 * */
export const getApplyWebInfo = (id) => get('/Home/Order/userApplyWebInfo', {id})

/*
 * 用户申请自主建站的模板
 * @constructor jack
 * id	int	false	申请的主键，存在表示修改
 * templateId	int	true	模板的id
 * componyName	string	true	机构名称
 * name	string	true	联系人
 * phone	string	true	联系电话
 * domainOk	int	true	是否有域名、1是、0否（默认）
 * spaceOk	int	true	是否有空间、1是、0否（默认）
 * */
export const userApplyWeb = (id,templateId,componyName,name,phone,domainOk,spaceOk) => qspost(
    '/Home/Order/userApplyWeb', {id,templateId,componyName,name,phone,domainOk,spaceOk})
